import React, { FC } from 'react';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import { useHistory } from 'react-router';

type Props = {
  data: { section: { id: number; name: string }; directChildIds: number[] }[];
};

const SectionsList: FC<Props> = ({ data }) => {
  const history = useHistory();
  const handleClick = (e: React.MouseEvent) => {
    const target = e.target as Element;
    history.push(`${history.location.pathname}/${target.id}`);
  };

  return (
    <List>
      {data.map((item) => (
        <ListItem
          id={String(item.section.id)}
          button
          key={item.section.id}
          alignItems="flex-start"
          onClick={handleClick}
          component={Typography}
          style={{ fontSize: 24 }}
          variant="body2"
        >
          {item.section.name}
        </ListItem>
      ))}
    </List>
  );
};

export default SectionsList;
