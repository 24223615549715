import { PaperWrapper } from 'HOC/PaperWrapper/PaperWrapper';
import React, { useState, useEffect } from 'react';
import { Fade, Typography, CircularProgress, Box } from '@material-ui/core';
import { doRequest } from 'hooks/doRequest';
import { getAllDetails } from 'api/sections';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Route, Switch, useHistory } from 'react-router';
import DetailEdit from 'components/DetailEdit/DetailEdit';
import { BrandButton } from 'ui/BrandButton/BrandButton';

const EndScreens = ({ pathname }: { pathname: string }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const history = useHistory();

  const getAllDetailsRequest = async () => {
    setIsFetching(true);
    const result = await doRequest<any | null>(getAllDetails);
    setIsFetching(false);
    setData(result.data?.data);
  };

  useEffect(() => {
    getAllDetailsRequest();
  }, []);

  const handleClick = (id: number) => () => {
    history.push(`${history.location.pathname}/${id}`);
  };

  const handleBack = () => {
    const route = history.location.pathname.split('/');
    history.push(`${route.slice(0, route.length - 1).join('/')}`);
  };

  return (
    <Fade in timeout={500}>
      <div>
        <Typography variant="h2">Детальные экраны</Typography>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <PaperWrapper>
            <Switch>
              <Route exact path={`${pathname}`}>
                <Box>
                  <BrandButton
                    variant="contained"
                    color="primary"
                    onClick={handleClick(-1)}
                  >
                    Создать
                  </BrandButton>
                  <List>
                    {data.map((i: any) => (
                      <ListItem
                        onClick={handleClick(i.id)}
                        id={i.id}
                        key={i.id}
                        button
                      >
                        <ListItemText primary={i.title} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Route>
              <Route path={`${pathname}/:blockId`}>
                <Box>
                  <BrandButton
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                  >
                    Назад
                  </BrandButton>
                  <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <DetailEdit allData={data} />
                  </Box>
                </Box>
              </Route>
            </Switch>
          </PaperWrapper>
        )}
      </div>
    </Fade>
  );
};

export default EndScreens;
