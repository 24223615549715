import { getMeRequest } from 'api/auth';
import { doRequest } from 'hooks/doRequest';
import {
  AuthActions,
  Employee,
  EmployeeState,
  LoginActions,
} from 'interface/login';
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';

export const getMe: ActionCreator<
  ThunkAction<void, EmployeeState, void, AuthActions>
> = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LoginActions.START_LOADING });
      const res = await doRequest<Employee>(getMeRequest);
      if (res.success) {
        setTimeout(() => {
          dispatch({ type: LoginActions.ME_SUCCESS, payload: res.data });
        }, 1000);
      } else {
        setTimeout(() => {
          dispatch({ type: LoginActions.ME_FAILED });
        }, 1000);
      }
    } catch (e) {
      setTimeout(() => {
        dispatch({ type: LoginActions.ME_FAILED });
      }, 1000);
    }
  };
};
