import { Fade, Grid, Typography } from '@material-ui/core';
import { ProfileFields } from './ProfileFields';
import React from 'react';
import { FilesList } from 'compounds/FilesList/FilesList';

const Profile: React.FC = () => {
  return (
    <Fade in timeout={500}>
      <div>
        <Typography variant="h2">Профиль</Typography>

        <Grid container spacing={3}>
          {/* <Grid item>
            <Avatar avatar={avatar} />
          </Grid> */}

          <Grid
            container
            alignItems="center"
            justify="center"
            spacing={2}
            item
            md={8}
            xs={6}
          >
            <ProfileFields />
          </Grid>

          <Grid item xs={12}>
            <FilesList title="Сертификаты">
              {_mockFiles.map((i, index) => (
                <FilesList.File key={index} {...i} />
              ))}

              <FilesList.AddButton title="Сертификаты" />
            </FilesList>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

const _mockFiles = [
  {
    name: 'FirstFile.jpg',
    photo: 'https://source.unsplash.com/250x150/?dog',
    canBeDeleted: true,
    handleDeletedCallBack: (id: number) => console.log('deleted ID', id),
    id: 1,
  },
];

export default Profile;
