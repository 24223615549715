import React from 'react';

export const LittleCloseIcon: React.FC = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1L7 7" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path d="M1 7L7 1" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
