import { useEffect, useState } from 'react';

type TCommonDebounce = (value: unknown, delay: number) => typeof value;

export const useCommonDebounce: TCommonDebounce = (
  value: unknown,
  delay: number
) => {
  const [debouncedValue, setDebouncedValue] = useState<typeof value>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};
