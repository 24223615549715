import React, { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';

interface Props {
  handleColorChange: (color: string) => void;
  initialColor: string;
}

export const ColorPicker = React.forwardRef((props: Props) => {
  const [color, setColor] = useState<string>(props.initialColor);

  const handleChangeComplete = (newColor: ColorResult) => {
    props.handleColorChange(newColor.hex);
    setColor(newColor.hex);
  };

  const handleChange = (newColor: ColorResult) => {
    setColor(newColor.hex);
  };
  return (
    <SketchPicker
      onChangeComplete={handleChangeComplete}
      onChange={handleChange}
      disableAlpha
      color={color}
    />
  );
});

ColorPicker.displayName = 'ColorPicker';
