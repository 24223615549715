import { Divider, Drawer, IconButton } from '@material-ui/core';
import React from 'react';
import { LinkList } from './LinkList';
import { useStyles } from './styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { PoltavaLogoText } from 'svgComponents/PoltavaLogoText';

interface Props {
  handleDrawerClose: () => void;
  open: boolean;
}

export const NavDrawer: React.FC<Props> = ({
  handleDrawerClose,
  open,
}: Props) => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <PoltavaLogoText />
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />

      <LinkList />
    </Drawer>
  );
};
