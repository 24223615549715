import React from 'react';

const Dots = ({ total, active }: any) => {
  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        bottom: 20,
        left: 'calc(50% + 8px)',
        transform: 'translateX(-50%)',
        background: 'rgba(0,0,0,.3)',
        padding: 8,
        borderRadius: 8,
        justifyContent: 'center',
      }}
    >
      {Array.from(Array(total)).map((_, ind) => (
        <div
          key={ind}
          style={{
            width: 6,
            height: 6,
            borderRadius: '100%',
            marginRight: 8,
            background: ind === active ? 'white' : 'rgba(255,255,255,.5)',
          }}
        />
      ))}
    </div>
  );
};

export default Dots;
