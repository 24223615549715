import React from 'react';
import { Fade, Typography, TableRow, TableCell } from '@material-ui/core';
import { DataTable } from 'common/DataTable/DataTable';
import { ITableBodyRenderer } from 'interface/global';
import { BlocksData } from 'interface/wisiwyg';
import { useHistory } from 'react-router';

const BannerList: React.FC = () => {
  const history = useHistory();

  const handleBodyRenderer: ITableBodyRenderer<BlocksData[]> = (data) =>
    data?.map((row: BlocksData) => {
      return (
        <TableRow
          hover
          role="checkbox"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push('/admin/terminal/banners/' + row.id)}
          key={row.id}
        >
          <TableCell scope="row">{row.id}</TableCell>
          <TableCell align="left">{row.title}</TableCell>
          <TableCell align="left">{row.shortDescription}</TableCell>
        </TableRow>
      );
    });
  return (
    <Fade in timeout={500}>
      <div>
        <Typography variant="h2">Гости</Typography>

        <DataTable
          header={['ID', 'Заголовок', 'Краткое описание']}
          bodyRenderer={handleBodyRenderer}
          endPoint={'/terminal/admin/banners'}
        />
      </div>
    </Fade>
  );
};

export default BannerList;
