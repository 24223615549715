import { makeStyles } from '@material-ui/core';

interface ISectionProps {
  editMode: boolean;
}

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    minHeight: 180,
    transition: '.3s',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    cursor: (props: ISectionProps) => (props.editMode ? 'pointer' : 'initial'),
    border: (props) => (props.editMode ? `1px solid rgba(0,0,0,.05)` : 'none'),
  },
  clock: {
    color: 'black',
    background: 'white',
    borderRadius: 12,
    padding: '8px 16px',
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: '32px 0px',
  },
  editButtonWrapper: {
    display: 'block',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
  },
  backdrop: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    background: 'rgba(0,0,0,.1)',
  },
  container: {},
}));
