import React from 'react';
import { Fade, Typography, TableRow, TableCell } from '@material-ui/core';
import { CurrentGuestCard } from './CurrentGuestCard';
import { DataTable } from 'common/DataTable/DataTable';
import { ITableBodyRenderer } from 'interface/global';
import { IAllGuests, IGuest } from 'interface/guests';

const Guests: React.FC = () => {
  const handleBodyRenderer: ITableBodyRenderer<IAllGuests> = (
    data,
    setSelectedRowInfo
  ) =>
    data?.result?.map((row: IGuest) => {
      const fio = `${row.surname} ${row.name} ${row.patronymic}`;
      return (
        <TableRow
          hover
          role="checkbox"
          style={{ cursor: 'pointer' }}
          onClick={() => setSelectedRowInfo && setSelectedRowInfo(row.id)}
          key={fio}
        >
          <TableCell scope="row">{row.id}</TableCell>
          <TableCell align="left">{fio}</TableCell>
          <TableCell align="left">{row.roomNumber}</TableCell>
        </TableRow>
      );
    });
  return (
    <Fade in timeout={500}>
      <div>
        <Typography variant="h2">Гости</Typography>

        <DataTable
          header={['ID', 'ФИО', 'Комната']}
          bodyRenderer={handleBodyRenderer}
          endPoint={'/guest/admin/all'}
          renderSideCard={(selectedRowInfo: number | null) => (
            <CurrentGuestCard selectedGuest={selectedRowInfo} />
          )}
        />
      </div>
    </Fade>
  );
};

export default Guests;
