import {
  ReservingData,
  ReservingActions,
  ReservingAction,
} from 'interface/reserving';
import moment from 'moment';

const initialState: ReservingData = {
  physician: null,
  patient: null,
  procedure: null,
  room: null,
  dateFrom: moment().format('yyyy-MM-D'),
  dateTo: moment().add(3, 'days').format('yyyy-MM-D'),
  comment: '',
};

export const reservingReducer = (
  state: ReservingData = initialState,
  action: ReservingAction
): ReservingData => {
  switch (action.type) {
    case ReservingActions.SET_COMMENT:
      return {
        ...state,
        comment: action.payload,
      };
    case ReservingActions.SET_DATE_TO:
      return {
        ...state,
        dateTo: action.payload,
      };
    case ReservingActions.SET_DATE_FROM:
      console.log('setDateFrom reducer', action);
      return {
        ...state,
        dateFrom: action.payload,
      };
    case ReservingActions.SET_PHYS:
      return {
        ...state,
        physician: action.payload,
      };
    case ReservingActions.SET_PAT:
      return {
        ...state,
        patient: action.payload,
      };
    case ReservingActions.SET_ROOM:
      return {
        ...state,
        room: action.payload,
      };
    case ReservingActions.SET_PROCEDURE:
      return {
        ...state,
        procedure: action.payload,
      };
    default:
      return { ...state };
  }
};
