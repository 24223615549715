import React from 'react';
import './loader.css';

export const Loader: React.FC = () => {
  return (
    <div className="wrapper-ripple">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
