import React, { FC } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useEmptyBlockStyles } from './styles';
import { useTheme } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import TextClamp from 'react-string-clamp';

type EmptyBlockProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  imgSrc?: string;
  describe?: string;
  disabled?: boolean;
  disabledReason?: string;
};

const EmptyBlock: FC<EmptyBlockProps> = ({
  onClick,
  imgSrc,
  describe,
  disabled,
  disabledReason,
}) => {
  const classes = useEmptyBlockStyles({ inside: !!imgSrc, disabled });
  const theme = useTheme();

  const handleClick = () => {
    if (disabled) return () => undefined;
    return onClick;
  };

  return (
    <Tooltip
      placement="top"
      title={disabledReason || ''}
      disableHoverListener={!disabled}
    >
      <div onClick={handleClick()} className={classes.root}>
        {imgSrc ? (
          <img src={imgSrc as string} alt="photo" />
        ) : (
          <AddIcon style={{ color: theme.palette.primary.main }} />
        )}
        <TextClamp lines={3} text={describe || 'Создать'} />
      </div>
    </Tooltip>
  );
};

export default EmptyBlock;
