import React from 'react';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useStyles } from './styles';
import { NavDrawer } from './NavDrawer';
import { UserMenu } from './UserMenu';
import { Container, Hidden } from '@material-ui/core';
import { SideMenu } from './SideMenu';
import { NavLink } from 'react-router-dom';
import { DRAWER_LINKS } from '_constants';
import { CustomLogo } from 'svgComponents/CustomLogo';

interface Props {
  children: React.ReactNode;
}

export const WithNavigation: React.FC<Props> = ({ children }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        style={{ background: 'white' }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Hidden lgUp>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Hidden mdDown>
            <div className={classes.logo}>
              <CustomLogo />
            </div>

            <div className={classes.navLinkContainer}>
              {DRAWER_LINKS.map((item) => (
                <NavLink
                  to={item.link}
                  key={item.link}
                  className={classes.navLink}
                  activeClassName={classes.activeNavLink}
                >
                  {item.title}
                </NavLink>
              ))}
            </div>
          </Hidden>

          <UserMenu />
        </Toolbar>
      </AppBar>

      {/* mobile drawer */}
      <NavDrawer open={open} handleDrawerClose={handleDrawerClose} />

      <div className={classes.contentContainer}>
        <SideMenu />
        <Container>
          <>
            {/* desktop side menu */}
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              {children}
            </main>
          </>
        </Container>
      </div>
    </div>
  );
};
