import { useIconListStyles } from 'common/SelectImageDialog/styles';
import React from 'react';
import { LittleCloseIcon } from 'svgComponents/LittleCloseIcon';

const DeleteLabel = ({ onClick }: { onClick: () => void }) => {
  const classes = useIconListStyles();
  return (
    <div className={classes.deleteWrapper} onClick={() => onClick()}>
      <LittleCloseIcon />
    </div>
  );
};

export default DeleteLabel;
