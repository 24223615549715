import React, { FC } from 'react';
import { useImageStyles } from './styles';

type Props = {
  hover?: boolean;
} & JSX.IntrinsicElements['img'];

const Image: FC<Props> = ({ hover, ...other }) => {
  const classes = useImageStyles({ hover });
  return other?.src ? (
    <img className={classes.root} {...other} />
  ) : (
    <div
      onClick={other.onClick}
      style={{
        width: '100%',
        height: 272,
        background: 'rgba(0,0,0,.1)',
        cursor: 'pointer',
      }}
    ></div>
  );
};

export default Image;
