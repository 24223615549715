import React from 'react';
import { useLabeledStyles } from './style';

interface Props {
  label: string;
  children?: React.ReactNode;
}

export const Labeled: React.FC<Props> = ({ label, children }: Props) => {
  const classes = useLabeledStyles();
  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}</div>
      {children}
    </div>
  );
};
