import { makeStyles, Theme } from '@material-ui/core';

export const useNotifyStyles = makeStyles((theme: Theme) => ({
  isActive: {
    fontSize: 30,
    fontWeight: 500,
    marginRight: 16,
    transition: '.3s',
    color: (props: { isActive: boolean }) =>
      props.isActive ? theme.palette.success.main : theme.palette.error.main,
  },
}));
