type RGBAColor = (
  hex: string,
  opacity?: string,
  isString?: boolean
) =>
  | {
      r: number;
      g: number;
      b: number;
      a: number;
    }
  | string;

export const hexToRgb: RGBAColor = (hex, opacity = '1', isString) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) || [];

  return isString
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
        result[3],
        16
      )}, ${parseFloat(opacity)})`
    : {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: parseInt(opacity || '1'),
      };
};
