import { makeStyles } from '@material-ui/core';

type Props = { inside?: boolean; disabled?: boolean };

export const useEmptyBlockStyles = makeStyles((theme) => ({
  root: {
    minWidth: 100,
    width: 100,
    maxWidth: 100,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    wordBreak: 'break-all',
    textAlign: 'center',
    cursor: ({ disabled }: Props) => (disabled ? 'default' : 'pointer'),
    transition: '.2s',
    backgorund: 'white',
    position: 'relative',

    '&:hover': {
      background: 'rgba(0,0,0, .1)',

      '& > img': {
        filter: 'brightness(0.7)',
      },
    },

    '& > *:first-child': {
      minHeight: 100,
      minWidth: 100,
      width: 100,
      height: 100,
      maxWidth: 100,
      maxHeight: 100,
      borderRadius: 10,
      border: ({ inside }: Props) =>
        `2px ${inside ? 'solid' : 'dashed'} ${theme.palette.primary.main}`,

      '&:not(svg)': {
        objectFit: 'cover',
      },

      '& + div': {
        marginTop: 8,
        maxHeight: 100,
      },
    },
  },
}));
