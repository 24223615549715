import { makeStyles, Theme } from '@material-ui/core';
import { hexToRgb } from 'utils/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  mainBg: {
    transition: '.3s',
    filter: 'brightness(80%)',
    cursor: 'pointer',
  },
  title: {
    margin: '24px 0',
    '& textarea': {
      fontWeight: 500,
      color: theme.palette.text.primary,
      fontSize: 43,
      paddingTop: 10,
    },
  },
  addBlockButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    transition: '.2s',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:hover': {
      background: hexToRgb(theme.palette.primary.main, '.1', true),
      transition: '.2s',
    },

    '& *': {
      color: theme.palette.primary.main,
    },
  },
  addBlockBar: {
    borderTop: `3px solid ${theme.palette.primary.main}`,
    padding: 8,
    paddingTop: 24,
  },
  addBlockButtonTitle: {
    fontSize: 16,
  },
  titleBlockBody: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    fontSize: 24,
  },
  paragraphBlockBody: {
    fontWeight: 400,
    fontSize: 22,
    color: theme.palette.text.primary,
  },
  uploadImageBlockLabel: {
    border: '1px solid rgba(0,0,0,.1)',
    cursor: 'pointer',
    display: 'block',
    borderRadius: 12,
    background: 'white',
    position: 'relative',
    transition: '.3s',

    '&:hover': {
      filter: 'brightness(.9)',
      transition: '.3s',
    },
  },
  uploadImageImg: {
    width: '100%',
    maxHeight: 406,
    objectFit: 'cover',
    borderRadius: 12,
  },
  addLiButton: {
    color: theme.palette.primary.main,
    textTransform: 'none',
  },
  plateWrapper: {
    padding: 24,
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: 396,
    height: 208,
  },
  plateContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-betewen',
    flexDirection: 'row',
  },
  imgWrapper: {
    widht: 128,
    height: 128,
    marginRight: 24,

    '& > img': {
      transition: '.3s',
      width: 128,
      height: 128,
      objectFit: 'cover',

      '&:hover': {
        cursor: 'pointer',
        transition: '.3s',
        filter: 'blur(3px)',
      },
    },
  },
  plateInfoWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  plateTitle: {
    fontWeight: 700,
    fontSize: 22,

    '& input': {
      fontWeight: 700,
      fontSize: 22,
    },
  },
  plateDescription: {
    fontWeight: 500,
    fontSize: 16,

    '& input': {
      fontWeight: 500,
      fontSize: 16,
      marginBottom: 16,
    },
  },
  plateDots: {
    width: '100%',
    textAlign: 'center',
  },
}));
