import React from 'react';

interface Props {
  fill?: string;
}

export const CustomLogo: React.FC<Props> = ({ fill }: Props) => {
  return (
    <svg
      width="44"
      height="32"
      viewBox="0 0 44 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.1545 26.2038C38.1099 25.9724 38.0133 25.7542 37.872 25.5658C37.7307 25.3773 37.5484 25.2234 37.3389 25.1159H37.3268C37.1895 25.0404 37.046 24.9769 36.8978 24.9262L36.8644 24.914L36.8129 24.8958C36.7712 24.8786 36.7281 24.8648 36.684 24.8549H36.6765H36.6552C36.4794 24.8055 36.2992 24.774 36.1171 24.7608C35.81 24.7278 35.501 24.7161 35.1923 24.7259C33.2639 24.7699 30.7883 25.4679 28.4416 26.2918C25.3474 27.3783 22.4883 28.5967 21.5635 28.9184C18.9424 29.8288 16.3773 30.4828 14.1594 30.6573C13.6849 30.6937 13.1922 30.7104 12.6889 30.7089C11.3906 30.6839 10.0961 30.5586 8.81708 30.3341C8.11063 30.2005 7.4345 30.0139 6.70228 29.8227H6.67498L6.50367 29.7757H6.49307H6.46427H6.44758H6.42333H6.40362H6.38847H6.37178C6.34505 29.7685 6.31758 29.7645 6.28992 29.7635C6.25202 29.7635 6.22929 29.7635 6.23535 29.7878C6.68105 30.0534 7.41024 30.2324 8.01664 30.4615C9.00205 30.8318 10.0289 31.0804 11.0744 31.202L11.2503 31.2308L11.4549 31.2582L11.6899 31.2855L11.9249 31.3128L12.3645 31.3401L12.8329 31.3674H14.6203L15.207 31.3371L15.8225 31.3067L16.1454 31.2764L16.438 31.246L16.7017 31.2157L16.9367 31.1868L17.2596 31.1565L17.4643 31.1277L17.669 31.0988L17.8448 31.07L18.0207 31.0412L18.1965 31.0123L18.4315 30.9835L18.6074 30.9547L18.7589 30.9258L18.9348 30.897L19.0864 30.8682L19.2911 30.8394L19.4427 30.8105C21.4953 30.4024 23.745 29.7332 25.9872 28.9897C28.6932 28.096 31.3932 27.137 33.8021 26.9139C34.1281 26.9139 35.4697 26.791 35.6471 27.3691C35.732 27.6347 35.5971 27.7925 35.2848 27.8077C35.1151 27.8163 34.9451 27.8163 34.7754 27.8077H34.6572H34.6283C34.5298 27.7958 34.4298 27.8062 34.3358 27.838C34.2563 27.893 34.1902 27.9652 34.1424 28.0493C34.0946 28.1333 34.0664 28.2271 34.0598 28.3236C34.0468 28.4045 34.0646 28.4874 34.1099 28.5557C34.3282 28.8759 34.877 29.0807 35.591 29.0367C36.3337 28.9827 37.0302 28.6557 37.5466 28.1187C37.8044 27.8791 37.9958 27.5769 38.1023 27.2413C38.2089 26.9058 38.2268 26.5484 38.1545 26.2038Z"
        fill={fill || '#208DFF'}
      />
      <path
        d="M8.31984 4.70577C3.01896 3.29891 0.186381 1.50262 0.280597 1.03159C0.343414 0.71755 8.02012 0.95048 16.9871 2.69596C25.2148 4.29752 26.8478 4.76858 28.6064 10.6724C30.3649 16.5762 31.6211 16.7332 32.9714 17.1729C34.3218 17.6125 35.819 17.2207 37.2109 17.0787C38.7496 16.9216 39.8173 18.115 40.1314 18.3662C40.4454 18.6174 40.6652 18.6488 41.89 18.9629C43.1147 19.2769 43.1147 19.8108 42.9263 20.0306C42.7378 20.2504 42.047 19.8108 40.6652 19.7165C39.2835 19.6223 39.3463 19.7793 36.3944 21.4751C33.4425 23.1709 30.0823 23.6106 27.6329 23.3907C25.4119 23.1914 22.2629 21.5693 21.1952 20.9099C20.1275 20.2504 17.559 19.7793 16.4847 18.2092C15.3651 16.5729 16.1078 15.5399 17.1442 15.7283C17.8857 15.8631 20.1589 17.5497 22.1373 17.7067C24.1157 17.8637 21.258 12.2112 20.1589 9.54188C19.0598 6.8726 14.9459 6.46436 8.31984 4.70577Z"
        fill={fill || '#208DFF'}
      />
    </svg>
  );
};
