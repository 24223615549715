/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { IEditNotify } from 'interface/notify';
import { instance } from './instance';

const { get, post } = instance;

export const getCurrentNotification = () =>
  get('/terminal/admin/notifications/global/editor');

export const postEditNotification = (data: IEditNotify) =>
  post('/terminal/admin/notifications/global', data);
