import { InputBase, Paper } from '@material-ui/core';
import { useDebounce } from 'hooks/useDebounce';
import React from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import SearchIcon from '@material-ui/icons/Search';

export const QueryFilter: React.FC = () => {
  const history = useHistory();
  const applyValue = (newValue: string) => {
    const parsedQuery = qs.parse(history.location.search);
    parsedQuery['query'] = newValue;

    history.push(history.location.pathname + `?${qs.stringify(parsedQuery)}`);
  };
  const [fieldValue, handleChange] = useDebounce('', applyValue, 500, true);
  return (
    <Paper component="form" style={{ marginBottom: 24 }}>
      <InputBase
        placeholder="Введите текст для поиска..."
        fullWidth
        value={fieldValue}
        onChange={handleChange}
        style={{ padding: '8px' }}
        startAdornment={
          <SearchIcon style={{ marginRight: 8, opacity: '.5' }} />
        }
      />
    </Paper>
  );
};
