import { InputBase } from '@material-ui/core';
import React, { useState } from 'react';
import { useBlocksStyles } from './ui/styles';
import { useDebounceFunction } from 'hooks/useDebounceFunction';

const Link = ({ setData: externalSetData, ...props }: any) => {
  const classes = useBlocksStyles();
  const [data, setData] = useState(props);

  const debounceSetData = useDebounceFunction(externalSetData, 400);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    debounceSetData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div>
      <InputBase
        value={data.text}
        onChange={handleChange}
        name={'text'}
        placeholder="Текст"
      />
      <InputBase
        className={classes.linkInput}
        value={data.link}
        onChange={handleChange}
        name={'link'}
        placeholder="Ссылка"
      />
    </div>
  );
};

export default Link;
