import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

export interface FileProps {
  name: string;
  photo: string;
  canBeDeleted: boolean;
  handleDeletedCallBack: (id: number) => void;
  id: number;
}

export const File: React.FC<FileProps> = ({
  name,
  photo,
  canBeDeleted,
  handleDeletedCallBack,
  id,
}: FileProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flexDirection: 'column',
        margin: '8px 8px 0 0',
      }}
      onClick={() => window.open(photo)}
    >
      <img
        src={photo}
        alt={name}
        style={{ height: '160px', width: 160, objectFit: 'cover' }}
      />
      <div style={{ fontSize: 12, marginTop: 8, textAlign: 'center' }}>
        {name}
      </div>

      {canBeDeleted && (
        <div
          style={{
            position: 'absolute',
            top: '-12px',
            right: '-12px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '4px solid white',
            borderRadius: '100%',
            backgroundColor: '#ff1744',
          }}
          onClick={() => handleDeletedCallBack(id)}
        >
          <CloseIcon
            style={{
              fontWeight: 'bold',
              color: 'white',
              height: 24,
              width: 24,
            }}
          />
        </div>
      )}
    </div>
  );
};
