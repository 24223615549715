import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import Profile from 'components/Profile/Profile';
import { Fade, Box } from '@material-ui/core';
import { Loader } from 'ui/Loader/Loader';
import { WithNavigation } from 'components/WithNavigation/WithNavigation';
import { GuestsPage } from 'pages/GuestsPage/GuestsPage';
import { TerminalPage } from 'pages/TerminalPage/TerminalPage';
import { PersonalPage } from 'pages/Personal/PersonalPage';
import InfoPage from 'pages/Info/InfoPage';

export const Admin: React.FC = () => {
  return (
    <Fade in timeout={300}>
      <>
        <WithNavigation>
          <Suspense
            fallback={
              <Box position="relative" height="100vh" width="100%">
                <Loader />
              </Box>
            }
          >
            <Switch>
              <Route path={'/admin/guests'}>
                <GuestsPage />
              </Route>
              <Route path={'/admin/terminal'}>
                <TerminalPage />
              </Route>
              <Route path={'/admin/profile'}>
                <Profile />
              </Route>
              <Route path={'/admin/personal'}>
                <PersonalPage />
              </Route>
              <Route path={'/admin/info'}>
                <InfoPage />
              </Route>
              <Route>
                <Redirect to="/admin/guests" />
              </Route>
            </Switch>
          </Suspense>
        </WithNavigation>
      </>
    </Fade>
  );
};
