import React from 'react';
import { parse as qsParse, stringify as qsStringify } from 'query-string';
import { useHistory, useLocation } from 'react-router';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

interface Props {
  totalCount?: number;
  onPageChange?: (newPage: number) => void;
  onRowChange?: (newRow: number) => void;
  count?: number;
  offset?: number;
  disabled?: string[];
  noCount?: boolean;
}

export const ListPagination: React.FC<Props> = ({
  totalCount = 1,
  onPageChange,
  onRowChange,
  disabled,
  noCount,
  count: outerCount,
  offset: outerOffset,
}: Props) => {
  const location = useLocation();
  const search = location.search;
  const history = useHistory();
  const count = outerCount || Number(qsParse(search)?.count || 10);
  const offset = outerOffset || Number(qsParse(search)?.offset || 10);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (!onPageChange) {
      const parsedSearch = qsParse(search);
      parsedSearch['offset'] = String((value - 1) * count);
      history.push(location.pathname + `?${qsStringify(parsedSearch)}`);
      return;
    }
    onPageChange(value);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const vl = event.target.value as number;
    if (!onRowChange) {
      const val = String(event.target.value);
      const parsedSearch = qsParse(search);
      parsedSearch['count'] = val;
      parsedSearch['offset'] = '0';
      history.push(location.pathname + `?${qsStringify(parsedSearch)}`);
      return;
    }

    onRowChange(vl);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 16,
      }}
    >
      <Pagination
        count={
          !noCount ? Math.ceil((totalCount * count) / count || 1) : undefined
        }
        variant="outlined"
        color="primary"
        shape="rounded"
        onChange={handlePageChange}
        page={Math.ceil((offset - count) / count) + 2}
      />
      {!disabled?.includes?.('rows') && (
        <FormControl variant="outlined" size="small">
          <InputLabel htmlFor="outlined-age-native-simple">Строк: </InputLabel>
          <Select
            native
            value={count}
            onChange={handleChange}
            label="Строк"
            inputProps={{
              name: 'age',
              id: 'outlined-age-native-simple',
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </Select>
        </FormControl>
      )}
    </div>
  );
};
