import { InputBase, makeStyles } from '@material-ui/core';
import { ICurrentNotify } from 'interface/notify';
import React, { useState } from 'react';
import { LittleCloseIcon } from 'svgComponents/LittleCloseIcon';
import { SelectImageDialog } from './SelectImageDialog/SelectImageDialog';

interface Props extends ICurrentNotify {
  editMode?: boolean;
  handleMessageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFileChange: (path: string) => void;
}

export const NotificationPlank: React.FC<Props> = (props: Props) => {
  const classes = usePlankStyles(props);
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={classes.wrapper}>
      <div className={classes.icon}>
        <img
          onClick={() => setOpen(true)}
          style={{ cursor: 'pointer', height: '100%' }}
          src={
            props.icon
              ? `${process.env.REACT_APP_SERVER_URL}${props.icon}`
              : `${process.env.REACT_APP_SERVER_URL}/artifacts/icons/terminal/ic_notification_unreaded.png`
          }
        />
        <SelectImageDialog
          endPoint={'/terminal/admin/notifications/icons'}
          open={open}
          setOpen={setOpen}
          onFileChange={props.onFileChange as any}
        />
      </div>
      <div className={classes.text}>
        {props.editMode ? (
          <InputBase
            value={props.message}
            fullWidth
            multiline
            onChange={props.handleMessageChange}
            margin="dense"
          />
        ) : (
          props.message
        )}
      </div>
      <div className={classes.escapeWrapper}>
        <LittleCloseIcon />
      </div>
    </div>
  );
};

const usePlankStyles = makeStyles(() => ({
  wrapper: {
    background: (props: ICurrentNotify) =>
      `linear-gradient(113.32deg, ${props.backgroundGradient.startColor} 28.63%, ${props.backgroundGradient.endColor} 73.17%)`,
    width: 375,
    height: 'auto',
    borderRadius: '26px 25px 0 0',
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
  },
  icon: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(0,0,0,.2)',
    padding: 9,
    minWidth: 40,
    minHeight: 40,
    borderRadius: '100%',
    margin: '0 auto 0 0',
  },
  escapeWrapper: {
    cursor: 'pointer',
    background: 'rgba(255,255,255,.2)',
    width: 22,
    minWidth: 22,
    height: 22,
    minHeight: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    margin: '0 0 0 auto',
  },
  text: {
    color: (props) => props.textColor,
    padding: '0 16px',
    fontSize: 15,
    lineHeight: '20px',
    letter: '0.4px',
    width: '100%',
    '& textarea': {
      color: (props) => props.textColor,
      fontSize: 15,
      lineHeight: '20px',
      letter: '0.4px',
    },
  },
}));
