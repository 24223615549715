import React from 'react';
import { Calendar } from './Calendar';
import { ReservingFields } from './ReservingFields';
import { Fade, Typography } from '@material-ui/core';
import { useShouldRender } from 'hooks/useShouldRender';

const Reserving: React.FC = () => {
  const { renderComponent } = useShouldRender(Calendar, 20);

  return (
    <Fade in timeout={500}>
      <div style={{ marginBottom: 128 }}>
        <Typography variant="h2">Бронирование</Typography>
        <ReservingFields />
        {renderComponent()}
      </div>
    </Fade>
  );
};

export default Reserving;
