import React from 'react';
import { Fade, Typography, TableRow, TableCell } from '@material-ui/core';
import { DataTable } from 'common/DataTable/DataTable';
import { ITableBodyRenderer } from 'interface/global';
import { IAllPersonal, IPersonal } from 'interface/personal';

const PersonalList: React.FC = () => {
  const handleBodyRenderer: ITableBodyRenderer<IAllPersonal> = (
    data,
    setSelectedRowInfo
  ) =>
    data?.result?.map((row: IPersonal) => {
      const fio = `${row.surname} ${row.name} ${row.patronymic}`;
      return (
        <TableRow
          hover
          role="checkbox"
          style={{ cursor: 'pointer' }}
          onClick={() => setSelectedRowInfo && setSelectedRowInfo(row.id)}
          key={fio}
        >
          <TableCell scope="row">{row.id}</TableCell>
          <TableCell align="left">{row.login}</TableCell>
          <TableCell align="left">{fio}</TableCell>
          <TableCell align="left">{row.role}</TableCell>
        </TableRow>
      );
    });

  return (
    <Fade in timeout={500}>
      <div>
        <Typography variant="h2">Персонал</Typography>

        <DataTable
          header={['ID', 'Логин', 'ФИО', 'Роль']}
          bodyRenderer={handleBodyRenderer}
          endPoint={'/employee/all'}
        />
      </div>
    </Fade>
  );
};

export default PersonalList;
