import EmptyBlock from 'components/EmptyBlock/EmptyBlock';
import { useStyledBlocksList } from 'pages/Blocks/components/styles';
import React, { FC } from 'react';
import { InfoBlockType } from 'types/global';

export type BlockListsType = {
  data: any[];
  handleCreateClick: () => void;
  handleClick: (id: number) => () => void;
  disabled?: boolean;
  disabledReason?: string;
  onSave?: (blockData: InfoBlockType) => any;
};

const BlockLIsts: FC<BlockListsType> = ({
  data,
  handleCreateClick,
  handleClick,
  disabled,
  disabledReason,
}) => {
  const classes = useStyledBlocksList();
  return (
    <div className={classes.root}>
      <EmptyBlock
        disabled={disabled}
        disabledReason={disabledReason}
        onClick={handleCreateClick}
      />
      {data.map((item: InfoBlockType) => (
        <EmptyBlock
          describe={item.name || item.title}
          imgSrc={item.media?.downloadUrl}
          key={item.id}
          onClick={handleClick(item.id)}
        />
      ))}
    </div>
  );
};

export default BlockLIsts;
