import React, { FC, useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import BrandSelect from 'ui/BrandSelect/BrandSelect';
import { EditBlockType, InfoBlockType, MediaType } from 'types/global';
import BlockLIsts from 'components/BlockLists/BlockLIsts';
import { BrandButton } from 'ui/BrandButton/BrandButton';
import { useBlockEditStyles } from './styles';
import { INFO_BLOCK_ENUM_VALUES, InfoBlockEnum } from 'enums/global';
import { makeEmptyInfoBlock } from 'utils/makeEmptyInfoBlock';
import { useParams } from 'react-router';
import ImageSelect from './components/ImageSelect';
import { getAllDetails, postCreateBlock, postEditBlock } from 'api/sections';
import { doRequest } from 'hooks/doRequest';
import Rating from '@material-ui/lab/Rating';
import moment from 'moment';
import { toast } from 'react-toastify';

const allDetailsRequest = async (cb: (data: any) => void) => {
  const result = await doRequest<any | null>(getAllDetails);
  cb(result.data?.data);
};

type BlockEditProps = {
  data: InfoBlockType;
  setHistory: React.Dispatch<React.SetStateAction<any[]>>;
  history: any[];
  onSave?: (blockData: InfoBlockType) => any;
  refetch: () => void;
};

const BlockEditComponent: FC<BlockEditProps> = React.memo(
  ({ data, setHistory, history, refetch }) => {
    const classes = useBlockEditStyles();
    const { sectionId } = useParams<{ sectionId: string }>();
    const [value, setValue] = useState<InfoBlockType>(data);
    const [isParent, setIsParent] = useState(
      +!!data.fullData?.innerInfoBlocks?.length || 0
    );

    const [detailsScreen, setDetailsScreen] = useState([]);

    useEffect(() => {
      setValue(data);
      setIsParent(data?.fullData?.innerInfoBlocksIds?.length);
    }, [data]);

    useEffect(() => {
      if (isParent || value.detailsScreenId || value.id === -1) {
        allDetailsRequest(setDetailsScreen);
      }
    }, [isParent]);

    const handleIsParentChange = (
      e: React.ChangeEvent<{
        name?: string;
        value: string;
      }>
    ) => {
      setIsParent(+!!e.target.value);
    };

    const handleSelectChange =
      (field: keyof typeof value) =>
      (
        e: React.ChangeEvent<{
          name?: string;
          value: any;
        }>
      ) => {
        setValue((prev) => ({
          ...prev,
          [field]: e.target.value,
        }));
      };

    const handleClick = (id: number) => () => {
      setHistory((prev) => [
        ...prev,
        value.fullData?.innerInfoBlocks?.find((i) => i.id === id),
      ]);
    };

    const handleCreateClick = () => {
      setHistory((prev) => [
        ...prev,
        makeEmptyInfoBlock({
          parent: value.id,
          type: InfoBlockEnum.room,
          section: Number(sectionId),
        }),
      ]);
    };

    const handleSaveBlock = async () => {
      const request = value.id === -1 ? postCreateBlock : postEditBlock;

      const copy = { ...value } as unknown as EditBlockType;

      copy['media'] = {
        uuid: value.media?.uuid || '',
        ext: value.media?.ext || '',
      };
      copy['parentInfoBlockId'] = history[history.length - 2]?.id || null;
      copy['fullData'] = {
        innerInfoBlocksIds: value?.fullData?.innerInfoBlocksIds || [],
      };
      copy['lastUpdate'] = moment().format('yyyy-MM-DD[T]HH:MM:ss');

      const res = await request(copy);
      if (res?.data?.data) {
        toast.success(`Блок ${value.id === -1 ? 'создан' : 'изменён'}`);
        setValue(res.data.data);
        setHistory((prev) => {
          const newHist = [...prev];
          newHist[newHist.length - 1] = res.data.data;
          const hasParent = newHist[newHist.length - 2];

          if (hasParent) {
            let parent = newHist[newHist.length - 2] as InfoBlockType;
            parent = {
              ...parent,
              fullData: {
                innerInfoBlocks: [
                  ...parent.fullData.innerInfoBlocks,
                  res.data.data,
                ],
                innerInfoBlocksIds: [
                  ...parent.fullData.innerInfoBlocksIds,
                  res.data.data.id,
                ],
              },
            } as InfoBlockType;
            newHist[newHist.length - 2] = parent;
          }

          return newHist;
        });
        /* setMeInParent */
        /* const meInHistory = prevHist.find((i: any) => i.id === value.id);
        const myIndex = prevHist.findIndex((i: any) => i.id === value.id);
        const newHist = [...prevHist];
        newHist[myIndex] = {
          ...meInHistory,
          fullData: {
            innerInfoBlocks: [...meInHistory.fullData.innerInfoBlocks, data],
            innerInfoBlocksIds: [
              ...meInHistory.fullData.innerInfoBlocksIds,
              data.id,
            ],
          },
        };
        return newHist; */
      } else {
        toast.error('Ошибка');
      }
    };

    const handleBack = () => {
      refetch && refetch();
      setHistory((prevHist: any[]) => prevHist.slice(0, prevHist.length - 1));
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue((prev) => ({
        ...prev,
        [e.target.name]: e.target.value || ' ',
      }));
    };

    const handleRatingChange = (_: any, newValue: number | null) => {
      setValue((prev) => ({
        ...prev,
        stars: newValue,
      }));
    };

    const handleFileSelect = (file: MediaType) => {
      setValue((prev) => ({
        ...prev,
        media: file,
      }));
    };

    const isImage = [InfoBlockEnum.icon, InfoBlockEnum.image].includes(
      value.type
    );

    const isRoom = [InfoBlockEnum.room, InfoBlockEnum.roomCategory].includes(
      value.type
    );

    return (
      <div
        style={{ margin: '0 auto', display: 'flex', justifyContent: 'center' }}
      >
        <div className={classes.root}>
          {!!history.length && (
            <BrandButton
              color="primary"
              variant="contained"
              onClick={handleBack}
              style={{ margin: '0 auto 32px 0' }}
            >
              Назад
            </BrandButton>
          )}
          <BrandSelect
            value={value.type}
            onChange={handleSelectChange('type')}
            label={'Тип'}
            selectId={'type-select'}
            options={INFO_BLOCK_ENUM_VALUES}
          />

          {isRoom && (
            <>
              <TextField
                fullWidth
                label={'Цена'}
                value={value.price}
                onChange={handleTextChange}
                name={'price'}
              />
              <TextField
                fullWidth
                label={'Количество'}
                value={value.count}
                onChange={handleTextChange}
                name={'count'}
              />
              <Rating
                value={parseInt(String(value.stars) || '0')}
                onChange={handleRatingChange}
              />
            </>
          )}

          {isRoom && (
            <TextField
              fullWidth
              label={'Название'}
              type="text"
              value={value.name}
              onChange={handleTextChange}
              name={'name'}
            />
          )}

          {isImage && (
            <TextField
              fullWidth
              label={'Название'}
              type="text"
              value={value.title}
              onChange={handleTextChange}
              name={'title'}
            />
          )}

          {isImage && (
            <TextField
              fullWidth
              label={'Подзаголовок'}
              type="text"
              value={value.subTitle}
              onChange={handleTextChange}
              name={'subTitle'}
            />
          )}
          <BrandSelect
            value={+isParent}
            onChange={handleIsParentChange}
            label={'Родительский блок'}
            selectId={'parent-select'}
            options={[
              { id: 1, title: 'Да' },
              { id: 0, title: 'Нет' },
            ]}
          />
          {!isParent && (
            <BrandSelect
              value={Number(value.detailsScreenId)}
              onChange={handleSelectChange('detailsScreenId')}
              label={'Конечный детальный экран'}
              selectId={'end-screen-select'}
              options={detailsScreen}
            />
          )}
          <ImageSelect
            outerFile={value.media || null}
            onFileSelect={handleFileSelect}
          />
          {!!isParent && (
            <BlockLIsts
              disabled={value.id === -1}
              disabledReason={
                'Чтобы добавить новый блок сначала сохраните этот'
              }
              handleClick={handleClick}
              handleCreateClick={handleCreateClick}
              data={value?.fullData?.innerInfoBlocks || []}
            />
          )}
          <BrandButton
            onClick={handleSaveBlock}
            fullWidth
            color="primary"
            variant="contained"
          >
            Сохранить
          </BrandButton>
        </div>
      </div>
    );
  }
);

export default BlockEditComponent;

/* if room category

  price
name
count
stars
*/

/* if room

  name
count
price
stars
*/

/* if icon

title
subTitle
*/

/* if image 

  title
subTitle
*/
