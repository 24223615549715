import axios from 'axios';

const poltavaServerHost = 'https://api.guest.poltava.prod.famedev.ru/';

export const instance = axios.create({
  baseURL: poltavaServerHost,
  withCredentials: true,
});

instance.interceptors.request.use(
  function (config) {
    const jwt = localStorage.getItem('jwt');

    if (jwt) {
      config.headers['Authorization'] = `Bearer ${jwt}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
