/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { instance } from './instance';
import qs from 'query-string';

export const getAllGuests = () =>
  instance.get('/guest/admin/all', {
    params: qs.parseUrl(window.location.href).query || {},
  });

export const getCurrentGuest = ({ id }: { id: number }) =>
  instance.get(`/guest/admin/${id}`);

export const getCurrentGuestQr = ({ guestUuid }: { guestUuid: string }) =>
  instance.get('/guest/admin/qr?uuid=' + guestUuid);
