import { Box } from '@material-ui/core';
import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import { BlockComponent, BLOCK_TYPE, IBlock } from 'interface/wisiwyg';
import React, { useState } from 'react';
import { useStyles } from '../styles';

export const ImageBlock: React.FC<BlockComponent & IBlock<BLOCK_TYPE.IMAGE>> = (
  props: BlockComponent & IBlock<BLOCK_TYPE.IMAGE>
) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const image = props.value
    ? `${process.env.REACT_APP_SERVER_URL}${props.value}`
    : false;

  const handleImageChange = (path: string) => {
    props.handleEditBody(path, props.index);
  };

  return props.editMode ? (
    <>
      <Box
        mb={4}
        clone
        boxSizing="border-box"
        width="100%"
        minHeight={!props.value && 300}
        height="100%"
        borderRadius={24}
      >
        <label
          onClick={() => {
            setOpen(true);
          }}
          className={classes.uploadImageBlockLabel}
        >
          {!!image && (
            <img className={classes.uploadImageImg} src={String(image)} />
          )}
        </label>
      </Box>
      <SelectImageDialog
        endPoint={'/terminal/admin/banners/image/block'}
        open={open}
        setOpen={setOpen}
        onFileChange={handleImageChange as any}
      />
    </>
  ) : (
    <Box
      mb={4}
      clone
      boxSizing="border-box"
      width="100%"
      height="100%"
      minHeight={406}
      borderRadius={24}
    >
      <img className={classes.uploadImageImg} src={String(image)} />
    </Box>
  );
};
