import React, { useState } from 'react';
import { GalleryItemType, GalleryType, MediaType } from 'types/global';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Image from 'ui/Image/Image';
import { IconButton } from '@material-ui/core';
import Dots from './Dots';
import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import AddIcon from '@material-ui/icons/Add';
import { useDebounceFunction } from 'hooks/useDebounceFunction';

const createSlide = (media?: MediaType): Partial<GalleryItemType> => ({
  media,
  description: '',
});

const Gallery = ({ setData: extSetData, ...props }: any) => {
  const [data, setData] = useState<GalleryType>(props);

  const debouncedSetData = useDebounceFunction(extSetData, 250);

  const [activeSlide, setActiveSlide] = useState(0);

  const [open, setOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);

  const handleSetFile = (icon: MediaType) => {
    const newState = {
      ...data,
      galleryItems: data.galleryItems.length
        ? data.galleryItems.map((item, ind) => {
            if (ind === activeSlide) {
              const newItem = { ...item };
              newItem.media = icon;
              return newItem;
            } else {
              return item;
            }
          })
        : [createSlide(icon)],
    };
    setData(newState);
    debouncedSetData(newState);
  };

  const handleCreateFile = (icon: MediaType) => {
    const newState = {
      ...data,
      galleryItems: [...data.galleryItems, { media: icon, description: 'bla' }],
    };
    setData(newState);
    debouncedSetData(newState);
  };

  const handleBack = () => {
    setActiveSlide(activeSlide - 1);
  };

  const handleForward = () => {
    setActiveSlide(activeSlide + 1);
  };

  return (
    <div
      style={{
        position: 'relative',
        height: 272,
      }}
    >
      <IconButton
        style={{
          position: 'absolute',
          top: '50%',
          left: '0',
          zIndex: 999,
          transform: 'translateY(-50%)',
          background: 'rgba(0,0,0,.3)',
          color: 'white',
          display: activeSlide === 0 ? 'none' : 'initial',
        }}
        disabled={activeSlide === 0}
        onClick={handleBack}
      >
        <ArrowBackIcon />
      </IconButton>
      <div id="carousel" style={{ position: 'relative' }}>
        {data?.galleryItems?.[activeSlide]?.media ? (
          <Image
            style={{ width: '100%', height: 272, objectFit: 'contain' }}
            hover
            onClick={() => setOpen(true)}
            src={data?.galleryItems[activeSlide]?.media?.downloadUrl}
            alt={data?.galleryItems[activeSlide]?.description}
          />
        ) : (
          <div
            onClick={() => setOpen(true)}
            style={{
              width: '100%',
              height: 272,
              background: 'rgba(0,0,0,.1)',
              cursor: 'pointer',
            }}
          ></div>
        )}
        <Dots total={data.galleryItems.length} active={activeSlide} />
      </div>
      <IconButton
        style={{
          position: 'absolute',
          top: '50%',
          right: '0',
          zIndex: 999,
          transform: 'translateY(-50%)',
          background: 'rgba(0,0,0,.3)',
          color: 'white',
          display:
            activeSlide === (data.galleryItems.length || 1) - 1
              ? 'none'
              : 'initial',
        }}
        disabled={activeSlide === data.galleryItems.length - 1}
        onClick={handleForward}
      >
        <ArrowForwardIcon />
      </IconButton>

      <IconButton
        style={{
          position: 'absolute',
          top: '50%',
          right: '0',
          zIndex: 999,
          transform: 'translateY(-50%)',
          background: 'rgba(0,0,0,.3)',
          color: 'white',
          display:
            activeSlide === (data.galleryItems.length || 1) - 1
              ? 'initial'
              : 'none',
        }}
        onClick={() => setCreateOpen(true)}
      >
        <AddIcon />
      </IconButton>
      <SelectImageDialog
        open={open}
        setOpen={setOpen}
        onFileChange={handleSetFile}
      />

      <SelectImageDialog
        open={createOpen}
        setOpen={setCreateOpen}
        onFileChange={handleCreateFile}
      />
    </div>
  );
};

export default Gallery;
