import React from 'react';

interface Props {
  title: string;
  body: string;
}

export const CardField: React.FC<Props> = ({ title, body }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        margin: '16px 0px',
        fontSize: 19,
      }}
    >
      <div style={{ fontWeight: 500, width: 170, minWidth: 170 }}>{title}:</div>
      <div>{body}</div>
    </div>
  );
};
