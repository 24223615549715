/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  Box,
  Button,
  Fade,
  IconButton,
  InputBase,
  useTheme,
} from '@material-ui/core';
import { useCommonDebounce } from 'hooks/useCommonDebounce';
import { BlockComponent, BLOCK_TYPE, IBlock, TEXT } from 'interface/wisiwyg';
import React, { useState, useEffect } from 'react';
import { useStyles } from '../styles';
import CloseIcon from '@material-ui/icons/Close';

export const ListBlock = ({
  value,
  editMode,
  handleEditBody,
  index,
}: IBlock<BLOCK_TYPE.LIST> & BlockComponent) => {
  const classes = useStyles();
  const theme = useTheme();

  const [values, setValues] = useState(value);
  const debouncedValue = useCommonDebounce(values, 400);

  useEffect(() => {
    handleEditBody(values, index);
  }, [debouncedValue]);

  const handleLiChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    inputIndex: number
  ) => {
    const valuesCopy = [...values];
    valuesCopy[inputIndex] = { rowType: TEXT, value: event.target.value };
    setValues(valuesCopy);
  };

  const handleAddLi = () => {
    setValues([...values, { rowType: TEXT, value: '' }]);
    handleEditBody([...values, { rowType: TEXT, value: '' }], index);
  };

  const handleDeleteLi = (inputIndex: number) => {
    handleEditBody(
      values.filter((_, ind) => ind !== inputIndex),
      index
    );
    setValues(values.filter((_, ind) => ind !== inputIndex));
  };

  return editMode ? (
    <Box clone>
      <ul>
        {value.map((val, i) => (
          <Fade key={i} in timeout={300}>
            <li>
              <InputBase
                value={values[i]?.value || ''}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleLiChange(event, i)
                }
                margin="dense"
                multiline
                style={{
                  borderBottom: '1px solid lightgray',
                  width: 'calc(100% - 32px)',
                }}
              />
              <IconButton onClick={() => handleDeleteLi(i)} size="small">
                <CloseIcon
                  fontSize="small"
                  htmlColor={theme.palette.error.main}
                />
              </IconButton>
            </li>
          </Fade>
        ))}
        <li>
          <Button
            className={classes.addLiButton}
            size="small"
            onClick={handleAddLi}
          >
            + Добавить пункт
          </Button>
        </li>
      </ul>
    </Box>
  ) : (
    <Box clone>
      <ul>
        {value.map((val, i) => (
          <Fade key={i} in timeout={300}>
            <li>{val}</li>
          </Fade>
        ))}
      </ul>
    </Box>
  );
};
