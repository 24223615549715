import React, { useRef } from 'react';
import {
  Fade,
  Typography,
  TableRow,
  TableCell,
  Box,
  Button,
} from '@material-ui/core';
import { DataTable } from 'common/DataTable/DataTable';
import { ITableBodyRenderer } from 'interface/global';
import { IAllGuests } from 'interface/guests';
import CreateInfoBannerCard from 'components/CreateInfoBannerCard/CreateInfoBannerCard';

const emptyBanner = {
  id: -1,
  media: null,
  title: '',
  subTitle: '',
  isActive: true,
};

const InfoBanners: React.FC = () => {
  const refetch = useRef<(() => void) | null>(null);
  const setCard = useRef<(arg: any) => void>(null);
  const handleBodyRenderer: ITableBodyRenderer<IAllGuests> = (
    data: any,
    setSelectedRowInfo,
    refetchRq
  ) => {
    console.log('refetch', refetchRq);
    refetch.current = refetchRq as () => void;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setCard.current = setSelectedRowInfo as () => void;
    return data?.data?.map((row: any) => {
      return (
        <TableRow
          hover
          role="checkbox"
          style={{ cursor: 'pointer' }}
          onClick={() => setSelectedRowInfo && setSelectedRowInfo(row)}
          key={row.id}
        >
          <TableCell scope="row">{row.id}</TableCell>
          <TableCell align="left">{row.title}</TableCell>
          <TableCell align="left">{row.subTitle}</TableCell>
          <TableCell align="left">{row.detailsScreen?.title || ''}</TableCell>
          <TableCell align="left">{row.isActive ? 'Да' : 'Нет'}</TableCell>
        </TableRow>
      );
    });
  };
  return (
    <Fade in timeout={500}>
      <div>
        <Box style={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant="h2">Баннеры</Typography>
          <Button
            style={{ marginLeft: 32 }}
            color="primary"
            variant="contained"
            onClick={() => setCard?.current?.(emptyBanner)}
          >
            Создать
          </Button>
        </Box>

        <DataTable
          header={[
            'ID',
            'Заголовок',
            'Подзаголовок',
            'Детальный экран',
            'Активен',
          ]}
          bodyRenderer={handleBodyRenderer}
          endPoint={'/info/admin/banners'}
          renderSideCard={(selectedRowInfo) =>
            selectedRowInfo ? (
              <CreateInfoBannerCard
                refresh={refetch.current}
                selectedRowInfo={selectedRowInfo}
              />
            ) : null
          }
        />
      </div>
    </Fade>
  );
};

export default InfoBanners;
