import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import React from 'react';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles';
import { useState } from 'react';
import { DRAWER_LINKS } from '_constants';
import { useHistory } from 'react-router';

export const LinkList: React.FC = () => {
  const [isOpen, setIsOpen] = useState<Record<number, boolean>>({});

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {renderMarkup(isOpen, setIsOpen)}
    </List>
  );
};

function renderMarkup(
  isOpen: Record<number, boolean>,
  setIsOpen: React.Dispatch<React.SetStateAction<Record<number, boolean>>>
) {
  const classes = useStyles();
  const history = useHistory();
  const markup: unknown[] = [];

  DRAWER_LINKS.forEach((item, index) => {
    const { Icon } = item;
    markup.push(
      <ListItem
        button
        key={item.link}
        onClick={() =>
          item.menu
            ? setIsOpen((prevState) => ({
                ...prevState,
                [index]: !isOpen[index],
              }))
            : history.push(item.link)
        }
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={item.title} />
        {item.menu ? isOpen[index] ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItem>
    );
    if (item.menu) {
      markup.push(
        <Collapse key={index} in={!!isOpen[index]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.menu.map((menuItem) => (
              <ListItem
                key={menuItem.link}
                onClick={() => history.push(menuItem.link)}
                button
                className={`${classes.nested} ${
                  history.location.pathname.includes(menuItem.link)
                    ? classes.activeListItem
                    : ''
                }`}
              >
                <ListItemIcon>{(() => menuItem.icon)()}</ListItemIcon>
                <ListItemText primary={menuItem.title} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      );
    }
  });

  return markup;
}
