import { InfoBlockEnum } from 'enums/global';

export type Args = {
  parent: number | null;
  type: InfoBlockEnum;
  section: number | null;
};

export const makeEmptyInfoBlock = ({ parent, type, section }: Args) => ({
  type,
  id: -1,
  infoSectionId: section,
  parentInfoBlockId: parent || null,
  detailsScreenId: null,
  media: null,
  fullData: {
    innerInfoBlocks: [],
    innerInfoBlocksIds: [],
    detailScreen: null,
  },
  title: '',
  subTitle: '',
  name: '',
  count: '',
  price: '',
  stars: '',
});
