import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  parse as qsparse,
  stringify as qsstringify,
  parseUrl,
} from 'query-string';
import { doRequest } from 'hooks/doRequest';
import { instance } from 'api/instance';

interface Props {
  endPoint: string;
}

interface ReturnType<T> {
  data: T | null;
  isFetching: boolean;
  refetch: () => Promise<void>;
}

function useHandleList<T>({ endPoint }: Props): ReturnType<T> {
  const history = useHistory();
  const searchValue = useLocation().search;
  const parsedSearch = qsparse(searchValue);
  const [isFetching, setIsFetching] = useState<boolean>(true);

  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    (async () => {
      const newParsedSearch = {
        ...parsedSearch,
        offset: '0',
        count: parsedSearch.count || 10,
      };
      history.push(
        history.location.pathname + `?${qsstringify(newParsedSearch)}`
      );
    })();
  }, [parsedSearch['query']]);

  const refetch = async () => {
    setIsFetching(true);
    const result = await doRequest<T | null>(getData as any, {
      endPoint,
    });
    setIsFetching(false);
    setData(result.data);
  };

  useEffect(() => {
    refetch();
  }, [qsstringify(parsedSearch)]);

  return { isFetching, data, refetch };
}

const getData = ({ endPoint }: { endPoint: string }) =>
  instance.get(endPoint, {
    params: parseUrl(window.location.href).query || {},
  });

export { useHandleList };
