import React, { useState } from 'react';
import { MediaType } from 'types/global';
import { Box } from '@material-ui/core';
import Image from 'ui/Image/Image';
import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import { DetailType } from 'enums/global';

const Img = ({ setData, ...props }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<{
    image: MediaType | null;
    type: DetailType.image;
  }>(props);

  const handleImageChange = (icon: MediaType) => {
    setFile((prev) => ({ ...prev, image: icon }));
    setOpen(false);
    setData({
      ...file,
      image: icon,
    });
  };
  return (
    <>
      <Box
        mb={4}
        clone
        boxSizing="border-box"
        width="100%"
        height="100%"
        borderRadius={24}
      >
        <label
          onClick={() => {
            setOpen(true);
          }}
        >
          {file.image ? (
            <Image
              hover
              style={{ width: '100%' }}
              src={file.image?.downloadUrl}
            />
          ) : (
            <div
              style={{
                width: '100%',
                height: 272,
                background: 'rgba(0,0,0,.1)',
                cursor: 'pointer',
              }}
            ></div>
          )}
        </label>
      </Box>
      <SelectImageDialog
        endPoint={'/uploads/images'}
        open={open}
        setOpen={setOpen}
        onFileChange={handleImageChange}
      />
    </>
  );
};

export default Img;
