import React, { useState } from 'react';
import { useFormik } from 'formik';
import { loginSchema } from 'schemas/loginSchema';
import { BrandTextFieldFormik } from 'ui/BrandTextField/BrandTextField';
import { BrandButton } from 'ui/BrandButton/BrandButton';
import { Box, CircularProgress } from '@material-ui/core';
import { doRequest } from 'hooks/doRequest';
import { tryLoginRequest } from 'api/auth';
import { useDispatch } from 'react-redux';
import { getMe } from 'redux/actions/loginActions';
import { toast } from 'react-toastify';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { ResponseError } from 'interface/global';

export const LoginForm: React.FC = () => {
  const { isLoading } = useTypedSelector((state) => state.user);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    onSubmit: async (values) => {
      setIsFetching(true);
      const res = await doRequest<any | ResponseError>(tryLoginRequest, {
        params: { ...values },
      });
      if (res.success && typeof res.data.data === 'string') {
        localStorage.setItem('jwt', res.data?.data);
        setIsFetching(false);
        dispatch(getMe());
      } else {
        if (typeof res?.data !== 'string')
          toast.error(res?.data?.userMessage || 'Ошибка сервера');

        setIsFetching(false);
      }
    },
    validationSchema: loginSchema,
  });

  return (
    <Box
      maxWidth={460}
      width="100%"
      display="flex"
      flexDirection="column"
      clone
    >
      <form onSubmit={formik.handleSubmit}>
        <BrandTextFieldFormik
          name="login"
          id="login"
          autoComplete="poltavaLogin"
          label="Логин"
          margin="normal"
          formik={formik}
        />
        <BrandTextFieldFormik
          name="password"
          id="password"
          type="password"
          autoComplete="poltavaPassword"
          label="Пароль"
          margin="normal"
          formik={formik}
        />
        <Box mt={2}>
          <BrandButton
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            type="submit"
            disabled={isFetching || isLoading}
            endIcon={
              isFetching || isLoading ? (
                <CircularProgress size={15} style={{ color: 'white' }} />
              ) : null
            }
          >
            Войти
          </BrandButton>
        </Box>
      </form>
    </Box>
  );
};
