import { SelectImageDialog } from 'common/SelectImageDialog/SelectImageDialog';
import { AddButton } from 'compounds/FilesList/AddButton';
import React, { useState, FC } from 'react';
import { Box } from '@material-ui/core';
import Image from 'ui/Image/Image';
import { MediaType } from 'types/global';

type Props = {
  onFileSelect: (icon: MediaType) => void;
  outerFile: MediaType | null;
};

const ImageSelect: FC<Props> = ({ onFileSelect, outerFile }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleImageChange = (icon: MediaType) => {
    onFileSelect(icon);
    setOpen(false);
  };

  return (
    <Box style={{ width: '100%' }}>
      <SelectImageDialog
        endPoint={'/uploads/images'}
        open={open}
        setOpen={setOpen}
        onFileChange={handleImageChange}
      />
      {!outerFile?.downloadUrl ? (
        <div style={{ margin: '0 auto 0 -8px' }}>
          <AddButton size={75} onClick={handleOpen} />
        </div>
      ) : (
        <Image
          style={{ width: '100%' }}
          hover
          src={outerFile?.downloadUrl}
          onClick={handleOpen}
          alt="Изображение блока"
        />
      )}
    </Box>
  );
};

export default ImageSelect;
