/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  TextField as MuiTextField,
  makeStyles,
  createStyles,
} from '@material-ui/core';

const BrandTextFieldFormik = (props) => {
  const {
    value = '',
    onChange,
    error,
    helperText,
    formik,
    ...otherProps
  } = props;

  const classes = useTextFieldStyles();

  const name = otherProps.name || '';
  return (
    <MuiTextField
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      variant="filled"
      InputProps={{ classes, disableUnderline: true }}
      {...otherProps}
    />
  );
};

const BrandTextField = (props) => {
  const classes = useTextFieldStyles();

  return (
    <MuiTextField
      variant="filled"
      InputProps={{ classes, disableUnderline: true }}
      {...props}
    />
  );
};

const useTextFieldStyles = makeStyles((theme) =>
  createStyles({
    root: {
      border: `1px solid ${theme.palette.info.main}`,
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      backgroundColor: 'transparent',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      caretColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: theme.palette.primary.light,
        background: 'transparent',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `none`,
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
    focused: {},
    error: {
      background: 'rgba(255,0,0,.2)',
      borderColor: 'rgba(255,0,0,.2)',
      '&:hover': {
        background: 'rgba(255,0,0,.05)',
        borderColor: 'rgba(255,0,0,.1)',
      },
      '&$focused': {
        boxShadow: `none`,
        background: 'rgba(255,0,0,.2)',
        borderColor: 'rgba(255,0,0,.2)',
        borderWidth: '2px',
      },
    },
  })
);

export { BrandTextField, BrandTextFieldFormik };
