import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router';

interface Props extends RouteProps {
  permission: boolean;
}

export const PrivateRoute: React.FC<Props> = (props: Props) => {
  const { permission, ...routeProps } = props;
  if (permission) {
    return (
      <>
        <Route {...routeProps} />
      </>
    );
  } else {
    return (
      <>
        <Redirect to="/" />
      </>
    );
  }
};
