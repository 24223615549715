import { Box } from '@material-ui/core';
import {
  BlocksData,
  BLOCK_TYPE,
  IBlock,
  TBlock,
  TEXT,
  THandleEditBody,
} from 'interface/wisiwyg';
import React from 'react';
import { AddBlockBar } from './AddBlockBar';
import { Block } from './Block';

interface Props {
  data: BlocksData;
  setData: React.Dispatch<React.SetStateAction<BlocksData>>;
  editMode: boolean;
}

export const RenderWisiwygBlocks: React.FC<Props> = ({
  data,
  setData,
  editMode = false,
}: Props) => {
  const handleAddBlock = (type: BLOCK_TYPE) => {
    /* баг тайпскрипт */
    if (type === BLOCK_TYPE.LIST) {
      const newData: IBlock<BLOCK_TYPE.LIST> = {
        value: [{ rowType: TEXT, value: '' }],
        type: BLOCK_TYPE.LIST,
      };
      setData((prevData) => ({
        ...prevData,
        blocks: [...prevData.blocks, newData],
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        blocks: [...prevData.blocks, { type, value: '' }],
      }));
    }
  };

  const handleDeleteBlock = (index: number) => {
    setData((prevData) => {
      const newBlocks = prevData.blocks.filter((_, i) => i !== index);
      return {
        ...prevData,
        blocks: newBlocks,
      };
    });
  };

  const handleEditBody: THandleEditBody = (newValue, index, file) => {
    if (Array.isArray(newValue)) {
      setData((prevData) => ({
        ...prevData,
        blocks: prevData.blocks.map((block, i) => {
          if (index !== i) return block;
          return { type: BLOCK_TYPE.LIST, value: newValue };
        }),
      }));
    } else {
      const dataCopy: BlocksData = { ...data };
      const blocksCopy: TBlock[] = [...dataCopy.blocks];
      const currentItem = blocksCopy[index];
      if (
        currentItem.type !== BLOCK_TYPE.LIST &&
        typeof newValue === 'string'
      ) {
        currentItem.value = newValue;
        if (file) currentItem.file = file;
      }

      blocksCopy[index] = currentItem;
      setData((prevData) => ({
        ...prevData,
        blocks: blocksCopy,
      }));
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      {data.blocks.map((block, i) => (
        <Block
          data={block}
          handleEditBody={handleEditBody}
          handleDeleteBlock={handleDeleteBlock}
          allData={data}
          setData={setData}
          editMode={editMode}
          key={i}
          index={i}
        />
      ))}
      {editMode && <AddBlockBar handleAddBlock={handleAddBlock} />}
    </Box>
  );
};
