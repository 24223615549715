/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/prop-types */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import {
  EditingState,
  GroupingState,
  IntegratedGrouping,
  IntegratedEditing,
  ViewState,
} from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  Resources,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  DragDropProvider,
  GroupingPanel,
  WeekView,
  DateNavigator,
  TodayButton,
  Toolbar,
} from '@devexpress/dx-react-scheduler-material-ui';
import { useState } from 'react';
import moment from 'moment';
import { CALENDAR_GROUPS } from '_constants';

const isWeekOrMonthView = (viewName) =>
  viewName === 'Week' || viewName === 'Month';

export const Calendar = ({ appointments = [] }) => {
  const [date, setDate] = useState(moment().format('yyyy-MM-D'));

  const currentDateChange = (currentDate) => {
    setDate(currentDate);
  };

  const [state, setState] = useState({
    data: [...appointments, ...MOCK_DATA],
    resources: [
      {
        fieldName: 'group',
        title: 'Type',
        instances: CALENDAR_GROUPS,
      },
    ],
    grouping: [
      {
        resourceName: 'group',
      },
    ],
    groupByDate: isWeekOrMonthView,
    isGroupByDate: true,
  });

  const commitChanges = ({ added, changed, deleted }) => {
    setState((prevState) => {
      let { data: changedData } = prevState;
      if (added) {
        const startingAddedId =
          changedData.length > 0
            ? changedData[changedData.length - 1].id + 1
            : 0;
        changedData = [...changedData, { id: startingAddedId, ...added }];
      }
      if (changed) {
        changedData = changedData.map((appointment) =>
          changed[appointment.id]
            ? { ...appointment, ...changed[appointment.id] }
            : appointment
        );
      }
      if (deleted !== undefined) {
        changedData = changedData.filter(
          (appointment) => appointment.id !== deleted
        );
      }
      return { ...prevState, data: changedData };
    });
  };

  const { data, resources, grouping, groupByDate } = state;

  return (
    <React.Fragment>
      <Paper>
        <Scheduler data={data} height={890} locale={'ru'}>
          <ViewState
            currentDate={date}
            onCurrentDateChange={currentDateChange}
          />
          <EditingState onCommitChanges={commitChanges} />
          <GroupingState grouping={grouping} groupByDate={groupByDate} />

          <WeekView
            startDayHour={8.5}
            endDayHour={18.5}
            excludedDays={[0, 6]}
            cellDuration={10}
            dayScaleCellComponent={DayScaleCell}
          />

          <Appointments />
          <Resources data={resources} mainResourceName="group" />
          <IntegratedGrouping />
          <IntegratedEditing />

          <AppointmentTooltip showOpenButton showDeleteButton />
          <AppointmentForm
            messages={{
              detailsLabel: 'Подробнее',
              allDayLabel: 'Весь день',
              titleLabel: 'Заголовок',
              commitCommand: 'Сохранить',
              moreInformationLabel: 'Дополнительная информация',
              repeatLabel: 'Повторять...',
              notesLabel: 'Заметки',
              never: 'Никогда',
              daily: 'Ежедневно',
              weekly: 'Еженедельно',
              monthly: 'Ежемесячно',
              yearly: 'Ежегодно',
              repeatEveryLabel: 'Повторять каждые...',
              daysLabel: 'дней',
              endRepeatLabel: 'Конец повторений',
              onLabel: 'В течении...',
              afterLabel: 'После',
              occurrencesLabel: 'дней',
              firstLabel: 'Первый',
              secondLabel: 'Второй',
              thirdLabel: 'Третий',
              fourthLabel: 'Четвёртый',
              lastLabel: 'Последний',
              yearsLabel: 'лет',
              everyLabel: 'Каждый',
            }}
          />

          <Toolbar />
          <DateNavigator />
          <TodayButton
            messages={{
              today: 'Сегодня',
            }}
          />

          <GroupingPanel />
          <DragDropProvider />
        </Scheduler>
      </Paper>
    </React.Fragment>
  );
};

const DayScaleCell = (props) => {
  return <WeekView.DayScaleCell {...props} />;
};

const MOCK_DATA = [
  {
    title: 'Install New Router in Dev Room',
    group: 1,
    startDate: new Date(2021, 4, 29, 13),
    endDate: new Date(2021, 4, 29, 15, 30),
    allDay: false,
    id: 1,
  },
  {
    title: 'Install New Router in Dev Room',
    group: 2,
    startDate: new Date(2021, 4, 29, 13),
    endDate: new Date(2021, 4, 29, 15, 30),
    allDay: false,
    id: 2,
  },
  {
    title: 'Install New Router in Dev Room',
    group: 1,
    startDate: new Date(2021, 4, 30, 13),
    endDate: new Date(2021, 4, 30, 15, 30),
    allDay: false,
    id: 3,
  },
  {
    title: 'Install New Router in Dev Room',
    group: 2,
    startDate: new Date(2021, 4, 30, 13),
    endDate: new Date(2021, 4, 30, 15, 30),
    allDay: false,
    id: 4,
  },
  {
    title: 'Install New Router in Dev Room',
    group: 1,
    startDate: new Date(2021, 4, 31, 13),
    endDate: new Date(2021, 4, 31, 15, 30),
    allDay: false,
    id: 5,
  },
];
