import PersonalList from 'components/PersonalLiist/PersonalList';
import React from 'react';
import { Redirect, Route } from 'react-router';

export const PersonalPage: React.FC = () => {
  return (
    <>
      <Route path={'/admin/personal/list'}>
        <PersonalList />
      </Route>
      <Route>
        <Redirect to={'/admin/personal/list'} />
      </Route>
    </>
  );
};
