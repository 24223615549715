export enum BLOCK_TYPE {
  TITLE = 'TITLE',
  PARAGRAPH = 'PARAGRAPH',
  IMAGE = 'IMAGE',
  LIST = 'LIST',
}
type ValueOf<T> = T[keyof T];

export const TEXT = 'TEXT';

interface BodyTypes {
  [BLOCK_TYPE.TITLE]: string;
  [BLOCK_TYPE.PARAGRAPH]: string;
  [BLOCK_TYPE.IMAGE]: string;
  [BLOCK_TYPE.LIST]: ListType[];
}

export interface ListType {
  rowType: string;
  value: string;
}

export interface IBlock<T extends BLOCK_TYPE> {
  type: T;
  value: BodyTypes[T];
  file?: string | ArrayBuffer;
}

export type THandleEditBody = (
  newValue: string | ListType[],
  index: number,
  file?: ArrayBuffer | null | string
) => void;

export type TBlock = ValueOf<{ [P in BLOCK_TYPE]: IBlock<P> }>;

export interface BlockComponent {
  editMode: boolean;
  handleEditBody: THandleEditBody;
  handleDeleteBlock?: (index: number) => void;
  index: number;
}

export type BlocksData = {
  id: number;
  isActive: boolean;
  title: string;
  shortDescription: string;
  mainImage: string;
  preview: string;
  blocks: TBlock[] | [];
};
