import BannerList from 'components/BannerList/BannerList';
import { Notify } from 'components/Notify/Notify';
import { Wisiwyg } from 'components/Wisiwyg/Wisiwyg';
import BlocksPage from 'pages/Blocks/BlocksPage';
import EndScreens from 'pages/EndScreens/EndScreens';
import React from 'react';
import { Route } from 'react-router';

export const TerminalPage: React.FC = () => {
  return (
    <>
      <Route exact path={'/admin/terminal/banners'}>
        <BannerList />
      </Route>
      <Route path={'/admin/terminal/createbanner'}>
        <Wisiwyg />
      </Route>
      <Route path={'/admin/terminal/banners/:bannerId'}>
        <Wisiwyg />
      </Route>
      <Route path={'/admin/terminal/notify'}>
        <Notify />
      </Route>
      <Route path={'/admin/terminal/sections'}>
        <BlocksPage pathname={'/admin/terminal/sections'} />
      </Route>
      <Route path={'/admin/terminal/endblocks'}>
        <EndScreens pathname="/admin/terminal/endblocks" />
      </Route>
      {/* <Route>
        <Redirect to={'/admin/terminal/banners'} />
      </Route> */}
    </>
  );
};
