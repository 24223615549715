import React from 'react';
import { useState } from 'react';
import { LittleCloseIcon } from 'svgComponents/LittleCloseIcon';
import { MediaType } from 'types/global';
import { useIconListStyles } from './styles';

interface Props {
  icon: MediaType;
  onClick: (icon: MediaType) => void;
  onDelete: (icon: MediaType) => void;
}

export const IconItem: React.FC<Props> = (props: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const classes = useIconListStyles();
  return (
    <div
      className={classes.iconItem}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {isHover && (
        <div
          className={classes.deleteWrapper}
          onClick={() => props.onDelete(props.icon)}
        >
          <LittleCloseIcon />
        </div>
      )}
      <img
        className={classes.imgWrapper}
        onClick={() => props.onClick(props.icon)}
        src={`${props.icon.downloadUrl}`}
      />
    </div>
  );
};
