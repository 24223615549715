import { makeStyles } from '@material-ui/core';
export const useImageStyles = makeStyles({
  root: {
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
      transition: '.3s',
      filter: 'brightness(.7)',
    },
  },
});
